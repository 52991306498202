<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">TRAVEL REQUESTS</span>
          <v-spacer></v-spacer>
          <v-flex
            xs12
            sm6
            md3
            text-right
            v-if="position == '62a2de91ecd959516cd3b0e6'"
          >
            <v-btn
              color="#005f32"
              rounded
              outlined
              :to="'/Attendance/addAssignees'"
              ><span style="font-family: kumbhRegular"
                >Update Assignees</span
              ></v-btn
            >
          </v-flex>
        </v-card-title>

        <v-layout
          wrap
          justify-start
          pa-0
          pa-xs-0
          pa-sm-5
          pa-md-5
          pa-lg-5
          pa-xl-5
        >
          <v-flex xs12>
            <v-card>
              <v-tabs background-color="#005f32" dark center-active fixed-tabs>
                <v-tab>Requests</v-tab>
                <v-tab>Assigned To Me</v-tab>
                <v-tab-item>
                  <v-card flat>
                    <v-layout wrap justify-start pt-5 px-4>
                      <v-flex xs12 pl-3 text-left>
                        <span class="kumbhRegular"
                          >*Select the options to filter the results</span
                        >
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap pt-5>
                          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2 text-left>
                            <span class="kumbhRegular">Status</span>
                            <v-select
                              :items="lists"
                              dense
                              v-model="status"
                              item-text="name"
                              item-value="value"
                              outlined
                            ></v-select>
                          </v-flex>

                          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2 text-left>
                            <v-layout wrap>
                              <span class="kumbhRegular">Month</span>
                            </v-layout>
                            <v-menu
                              ref="menu2"
                              v-model="menu2"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="computedDateFormatted"
                                  outlined
                                  hide-details
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  clearable
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="date"
                                no-title
                                type="month"
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2 text-left>
                            <span class="kumbhRegular">Mode of Travel</span>
                            <v-select
                              :items="travelModes"
                              dense
                              v-model="travelMode"
                              outlined
                              item-text="name"
                              item-value="value"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-simple-table class="align-center">
                      <template v-slot:default>
                        <thead style="background-color: #005f32">
                          <tr>
                            <th
                              style="
                                font-size: 15px;
                                color: #ffffff;
                                font-family: kumbhRegular;
                              "
                              class="text-left"
                            >
                              Employee ID
                            </th>
                            <th
                              style="
                                font-size: 15px;
                                color: #ffffff;
                                font-family: kumbhRegular;
                              "
                              class="text-left"
                            >
                              Employee Name
                            </th>
                            <th
                              style="
                                font-size: 15px;
                                color: #ffffff;
                                font-family: kumbhRegular;
                              "
                              class="text-left"
                            >
                              Source
                            </th>
                            <th
                              style="
                                font-size: 15px;
                                color: #ffffff;
                                font-family: kumbhRegular;
                              "
                              class="text-left"
                            >
                              Destination
                            </th>
                            <th
                              style="
                                font-size: 15px;
                                color: #ffffff;
                                font-family: kumbhRegular;
                              "
                              class="text-left"
                            >
                              Travel Mode
                            </th>
                            <th
                              style="
                                font-size: 15px;
                                color: #ffffff;
                                font-family: kumbhRegular;
                              "
                              class="text-left"
                            >
                              Applied Date
                            </th>
                            <th
                              style="
                                font-size: 15px;
                                color: #ffffff;
                                font-family: kumbhRegular;
                              "
                              class="text-left"
                            >
                              Status
                            </th>
                            <th
                              style="
                                font-size: 15px;
                                color: #ffffff;
                                font-family: kumbhRegular;
                              "
                              class="text-left"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody class="align-center">
                          <tr
                            class="text-left align-center"
                            v-for="(item, i) in travel"
                            :key="i"
                          >
                            <td>
                              <span
                                v-if="item.uid.employeeId"
                                style="font-family: kumbhMedium"
                                >{{ item.uid.employeeId }}</span
                              >
                            </td>
                            <td>
                              <span
                                v-if="item.uid.name"
                                style="font-family: kumbhMedium"
                                >{{ item.uid.name }}</span
                              >
                            </td>
                            <td>
                              <span
                                v-if="item.source"
                                style="font-family: kumbhMedium"
                                >{{ item.source }}</span
                              >
                            </td>

                            <td>
                              <span
                                v-if="item.destination"
                                class=""
                                style="font-family: kumbhMedium"
                                >{{ item.destination }}</span
                              >
                            </td>
                            <td>
                              <span
                                v-if="item.travelMode"
                                class=""
                                style="font-family: kumbhMedium"
                                >{{ item.travelMode }}</span
                              >
                            </td>
                            <td>
                              <span
                                v-if="item.create_date"
                                class=""
                                style="font-family: kumbhMedium"
                                >{{ formatDate(item.create_date) }}</span
                              >
                            </td>
                            <td>
                              <v-btn
                                flat
                                elevation="0"
                                small
                                :color="getStatusColor(item.status)"
                              >
                                <span
                                  v-if="item.status"
                                  class=""
                                  style="font-family: kumbhMedium"
                                  >{{ item.status }}</span
                                ></v-btn
                              >
                            </td>
                            <td>
                              <v-layout wrap>
                                <v-flex xs12 align-self-center text-left pa-2>
                                  <router-link
                                    :to="
                                      '/Attendance/travelRequestDetails?id=' +
                                      item._id
                                    "
                                  >
                                    <v-icon title="View" color="#005f32"
                                      >mdi-eye</v-icon
                                    >
                                  </router-link>
                                </v-flex>
                              </v-layout>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-layout wrap justify-center v-if="pages > 1">
                      <v-flex align-self-center>
                        <div class="pt-2">
                          <v-pagination
                            prev-icon="mdi-menu-left"
                            next-icon="mdi-menu-right"
                            :length="pages"
                            :total-visible="7"
                            v-model="currentPage"
                            color="#005f32"
                          ></v-pagination>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-layout
                      wrap
                      justify-start
                      py-5
                      v-if="assignedArray.length > 0"
                    >
                      <v-flex xs12>
                        <v-simple-table class="align-center">
                          <template v-slot:default>
                            <thead style="background-color: #005f32">
                              <tr>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Employee ID
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Employee Name
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Source
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Destination
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Travel Mode
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Applied Date
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Status
                                </th>
                                <th
                                  style="
                                    font-size: 15px;
                                    color: #ffffff;
                                    font-family: kumbhRegular;
                                  "
                                  class="text-left"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody class="align-center">
                              <tr
                                class="text-left align-center"
                                v-for="(list, l) in assignedArray"
                                :key="l"
                              >
                                <td>
                                  <span
                                    v-if="list.uid.employeeId"
                                    style="font-family: kumbhMedium"
                                    >{{ list.uid.employeeId }}</span
                                  >
                                </td>
                                <td>
                                  <span
                                    v-if="list.uid.name"
                                    style="font-family: kumbhMedium"
                                    >{{ list.uid.name }}</span
                                  >
                                </td>
                                <td>
                                  <span
                                    v-if="list.source"
                                    style="font-family: kumbhMedium"
                                    >{{ list.source }}</span
                                  >
                                </td>

                                <td>
                                  <span
                                    v-if="list.destination"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ list.destination }}</span
                                  >
                                </td>
                                <td>
                                  <span
                                    v-if="list.travelMode"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ list.travelMode }}</span
                                  >
                                </td>
                                <td>
                                  <span
                                    v-if="list.create_date"
                                    class=""
                                    style="font-family: kumbhMedium"
                                    >{{ formatDate(list.create_date) }}</span
                                  >
                                </td>
                                <td>
                                  <v-btn
                                    elevation="0"
                                    small
                                    :color="getStatusColor(list.status)"
                                  >
                                    <span
                                      v-if="list.status"
                                      class=""
                                      style="font-family: kumbhMedium"
                                      >{{ list.status }}</span
                                    ></v-btn
                                  >
                                </td>
                                <td>
                                  <v-layout wrap>
                                    <v-flex
                                      xs12
                                      align-self-center
                                      text-left
                                      pa-2
                                    >
                                      <v-btn
                                        color="#005f32"
                                        dark
                                        small
                                        :to="
                                          '/Attendance/assignDetails?id=' +
                                          list._id
                                        "
                                        ><span
                                          style="
                                            font-family: kumbhRegular;
                                            color: white;
                                          "
                                          >View Details</span
                                        ></v-btn
                                      >
                                    </v-flex>
                                  </v-layout>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap v-else>
                      <v-flex xs12 align-self-center pa-16>
                        <span class="kumbhRegular">No Data found!</span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data: (vm) => ({
    showsnackbar: false,
    ServerError: false,
    msg: null,
    pages: 0,
    dialog: false,
    pageCount: 1,
    appLoading: false,
    editedIndex: -1,
    currentPage: 1,
    g: false,
    totalData: 0,
    addcat: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    travel: [],
    data: [],
    name: null,
    assetId: null,
    date: new Date().toISOString().substr(0, 7),
    dateFormatted: vm.formatDateMonth(new Date().toISOString().substr(0, 7)),
    menu1: false,
    menu2: false,
    msg1: "",
    msg2: "",
    msg3: "",
    msg4: "",
    status: "",
    travelMode: "",
    computedDateFormatted: "",
    lists: [
      {
        name: "All",
        value: "",
      },
      {
        name: "Pending",
        value: "pending",
      },
      {
        name: "Approved",
        value: "approved",
      },
      {
        name: "Rejected",
        value: "rejected",
      },
      {
        name: "Started",
        value: "started",
      },
      {
        name: "Completed",
        value: "completed",
      },
    ],
    travelModes: [],
    assignedArray: [],
  }),
  computed: {
    // computedDateFormatted() {
    //   return this.formatDateMonth(this.date);
    // },
    position() {
      return localStorage.getItem("positionId");
    },
  },
  mounted() {
    this.computedDateFormatted = this.formatDateMonth(this.date);
    if (!this.$store.state.date) {
      this.getTravelMode();
      this.getData();
      this.getAssign();
      return this.computedDateFormatted;
    } else if (
      this.$store.state.status ||
      this.$store.state.date ||
      this.currentPage
    ) {
      console.log("haii", this.computedDateFormatted);
      this.date = this.$store.state.date;
      this.status = this.$store.state.status;
      this.travelMode=this.$store.state.travelMode
      this.getData();
      this.getTravelMode();
      this.getAssign();
    }
  },
  watch: {
    date() {
      this.currentPage = 1;
      // this.dateFormatted = this.formatDateMonth(this.date);
      // if (this.computedDateFormatted) {
      //   this.getData();
      // }
      this.computedDateFormatted = this.formatDateMonth(this.date);
      if (this.computedDateFormatted) {
        this.getData();
        this.$store.commit("changeTravelDate", this.date);
      }
    },
    status() {
      this.currentPage = 1;
      this.getData();
      this.$store.commit("changeTravelStatus", this.status);
    },
    travelMode() {
      this.getData();
      this.$store.commit("changeTravel", this.travelMode);
    },
    currentPage() {
      this.getData();
    },
  },

  methods: {
    getStatusColor(status) {
      if (status == "pending") {
        return "warning";
      }
      if (status == "assigned") {
        return "secondary";
      }
      if (status == "approved") {
        return "secondary";
      }
      if (status == "rejected") {
        return "danger";
      }
      if (status == "pending") {
        return "warning";
      }
      if (status == "started") {
        return "info";
      }
      if (status == "completed") {
        return "success";
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/employee/travel/application/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          page: this.currentPage,
          limit: 20,
          month: this.computedDateFormatted,
          status: this.status,
          travelMode: this.travelMode,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.travel = response.data.data;
          this.totalData = response.data.length;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getAssign() {
      this.appLoading = true;
      axios({
        url: "/employee/travel/application/assigned/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          page: this.currentPage,
          limit: 20,
          month: this.computedDateFormatted,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.assignedArray = response.data.data;
          this.totalData = response.data.length;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getTravelMode() {
      axios({
        method: "GET",
        url: "/travelMode/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // console.log(response.data.data)
          if (response.data.status) {
            // this.travelModes = response.data.data;
            this.travelModes = [
              { name: "All", value: "" },
              ...response.data.data,
            ];
          }
        })
        .catch((e) => {
          this.visible = false;
          this.errors.push(e);
          this.msg = "failed";
        });
    },
    formatDateMonth(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${month}-${year}`;
    },
    formatDate(date) {
      var dt = new Date(date);
      var day = String(dt.getDate()).padStart(2, "0"); // Ensure two digits
      var month = String(dt.getMonth() + 1).padStart(2, "0"); // Ensure two digits
      var year = dt.getFullYear();
      var strTime = day + "-" + month + "-" + year;
      return strTime;
    },
  },
};
</script>
  <style>
</style>